import React, { useState, useEffect, useRef } from "react";
import { HashLink as Link } from "react-router-hash-link";
// import { isMobile } from "react-device-detect";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Slider from "../components/Slider";
import Testimonials from "../components/Testimonials";
import Partners from "../components/Partners";

import styles from "../assets/css/home.module.css";
// import engage from "../assets/images/engage.png";
// import analyze from "../assets/images/analyze.jpg";
// import create from "../assets/images/create.png";
// import inspire from "../assets/images/inspire.jpg";
// import community from "../assets/images/community image.png";
// import communityMobile from "../assets/images/community image mobile.png";
// import arImage from "../assets/images/AR Image.png";
// import arImageMobile from "../assets/images/AR Image mobile.png";
// import audience from "../assets/images/reach audiences.png";
// import audienceMobile from "../assets/images/reach audiences mobile.png";
// import ar from "../assets/images/AR.png";
// import heart from "../assets/images/heart.png";
// import edit from "../assets/images/edit.png";
// import message from "../assets/images/message.png";
// import user from "../assets/images/user.png";
// import analytics from "../assets/images/analytics.png";
// import location from "../assets/images/location.png";
// import mail from "../assets/images/mail.png";

// New Icons
import apiIntegrationsIcon from "../assets/images/new_api_integrations_icon.png";
import augmentedRealityIcon from "../assets/images/new_augmented_reality_icon.png";
import eventProducerIcon from "../assets/images/new_event_producer_icon.png";
import expertEventConsultationIcon from "../assets/images/new_expert_event_consultation_icon.png";
import mobileAppIcon from "../assets/images/new_mobile_app_icon.png";
import professionalDesignIcon from "../assets/images/new_professional_design_icon.png";
import whiteLabelicon from "../assets/images/new_white_label_icon.png";
import interactiveFloorplansIcon from "../assets/images/newinteractive_floorplans_icon.png";

import tradeshow from "../assets/images/experiences/trade-show.png";
import conferences from "../assets/images/experiences/conference.png";
import corporateEvent from "../assets/images/experiences/corperate-events.png";
import productLaunches from "../assets/images/experiences/product-launch.png";
import onlineTraining from "../assets/images/experiences/online-training.png";
import galias from "../assets/images/experiences/gala.png";
import shopping from "../assets/images/experiences/marketplace.png";
import career from "../assets/images/experiences/career.png";
import socialEvents from "../assets/images/experiences/social-events.png";
import education from "../assets/images/experiences/education.png";
import destination from "../assets/images/experiences/destination.png";
import office from "../assets/images/experiences/office.png";
// import check from "../assets/images/check-circle.png";
// import left from "../assets/images/left.png";
// import right from "../assets/images/right.png";
// import playIcon from "../assets/images/play icon.png";
import buildCommunity from "../assets/images/build_community.png";
import reachAudiences from "../assets/images/reach_audiences.png";
import standOut from "../assets/images/stand_out.png";
import buildCommunityMobile from "../assets/images/build_community_mobile.png";
import reachAudiencesMobile from "../assets/images/reach_audiences_mobile.png";
import standOutMobile from "../assets/images/stand_out_mobile.png";
import photoCreate from "../assets/images/new_create.png";
import photoEngage from "../assets/images/photo_engage.png";
import photoInspire from "../assets/images/new_inspire.png";
import photoAnalyze from "../assets/images/photo_analyze.png";
// import video from "../assets/videos/livex_sizzle_reel.mp4";



import TagManager from "react-gtm-module";
const tagManagerArgs = {
  dataLayer: {
    page: "home",
  },
  dataLayerName: "PageDataLayer",
};

const Home = () => {
  /////TAG Manager////////////////////
  TagManager.dataLayer(tagManagerArgs);
  ////////////////////////////////////
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    if(document.querySelector('#vidyard')) {
      document.querySelector('#vidyard').remove();
    }
    const script = document.createElement("script");
    script.src = "https://play.vidyard.com/embed/v4.js";
    script.id = "vidyard";
    document.body.appendChild(script);

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const vidRef = useRef(null);
  // const [videoControls, setVideoControls] = useState(false);
  // const handlePlayVideo = () => {
  //   setVideoControls(true);
  //   vidRef.current.play();
  // };

  let isMobile = width <= 600 ? true : false;

  const experience = [
    { img: tradeshow, title: "Trade Shows, Expos, and Conventions" },
    { img: conferences, title: "Conferences" },
    { img: corporateEvent, title: "Corporate Programs" },
    { img: productLaunches, title: "Product Launches & Showrooms" },
    { img: onlineTraining, title: "Online Training & Poster Sessions" },
    { img: galias, title: "Galas & Award Ceremonies" },
    { img: shopping, title: "Marketplaces" },
    { img: career, title: "Career & Education Fairs" },
    { img: socialEvents, title: "Milestones, Private, or Social Events" },
    { img: education, title: "Augmented Learning" },
    { img: destination, title: "Digital Destination" },
    { img: office, title: "Digital Office" },
  ];

  const pricing = [
    {
      title: "Starter",
      price: "$188",
      features: [
        "200 registered users included per month",
        "4% transaction fee (booth, ticket, and sponsorship sales)",
        "2 organizers (admins) included",
        "$2.00 per additional registration",
        "Access to 24/7 on-demand training support",
      ],
      class: styles.starter,
    },
    {
      title: "Growth",
      price: "$888",
      features: [
        "500 registered users included per month",
        "3% transaction fee (booth, ticket, and sponsorship sales)",
        "5 organizers (admins) included",
        "$2.00 per additional registration",
        "Access to 24/7 on-demand training support",
        "1 session and 2 hours of live onboarding training per client account",
        "Dedicated Experience Excellence Manager",
      ],
      class: styles.growth,
    },
    {
      title: "Pro",
      price: "",
      features: [
        "1000 registered users included per month",
        "2% transaction fee (booth, ticket, and sponsorship sales)",
        "10 organizers (admins) included",
        "$1.50 per additional registration",
        "Access to 24/7 on-demand training support",
        "1 session and 2 hours of live onboarding training per client account",
        "Dedicated Experience Excellence Manager",
      ],
      class: styles.pro,
    },
    {
      title: "Enterprise",
      price: "",
      features: [
        "Custom number of registered users included per month",
        "2% transaction fee (booth, ticket, and sponsorship sales)",
        "Dedicated Experience Excellence Manager",
        "Event experts to customize your virtual events",
        "Live technical organizer support during event day",
        "Custom venue options",
      ],
      class: styles.enterprise,
    },
  ];

  return (
    <div>
      <Navbar />
      <div className={styles.mainWrapper}>
        <div className={styles.main}>
          {isMobile ? (
            <h1>
              Your Ultimate
              <br />
              Hybrid and Digital
              <br />
              Experience Platform
            </h1>
          ) : (
            <h1>
              Your Ultimate
              <br />
              Hybrid and Digital
              <br />
              Experience Platform
            </h1>
          )}

          <p>
            <span style={{ fontWeight: "normal" }}>Nextech AR LiveX</span>{" "}
            will empower you to create events that inspire people around the
            world
          </p>

          <div className={styles.buttonColumn}>
            {/* <button className={styles.getStarted}>Get Started</button> */}
            <Link
              className={styles.link}
              to={`${process.env.PUBLIC_URL + "/contact"}`}
            >
              <button className={styles.viewDemo}>Book a Demo</button>
            </Link>
          </div>
          <div className={styles.videoColumn}>
            <div className={styles.videoBox}>

              <img
                styles={{
                  width: "100%",
                  margin: "auto",
                  display: "block",
                }}
                alt=""
                className="vidyard-player-embed"
                src="https://play.vidyard.com/D4f2s2CzQwKYxPj7pjCmbf.jpg"
                data-uuid="D4f2s2CzQwKYxPj7pjCmbf"
                data-v="4"
                data-type="inline"
              />

              {/* <img
                styles={{
                  width: "100%",
                  margin: "auto",
                  display: "block",
                }}
                alt=""
                className="vidyard-player-embed"
                src="https://play.vidyard.com/xiQHYm9ZxEPriv7NzMrF79.jpg"
                data-uuid="xiQHYm9ZxEPriv7NzMrF79"
                data-v="4"
                data-type="inline"
              /> */}

              {/* <video
                ref={vidRef}
                className={styles.video}
                controls={videoControls}
                disablePictureInPicture
                controlsList="nodownload"
                src={video}
              ></video> */}
            </div>
            {/* {!videoControls && (
              <img src={playIcon} alt="" onClick={handlePlayVideo}></img>
            )} */}
          </div>
        </div>
      </div>

      <div className={styles.bodyWrapper}>
        <div className={styles.background}>
          <div id="what-we-do" className={styles.anchor}></div>

          <h1 className={styles.title}>What We Do</h1>
          <div className={styles.whatWeDoBox}>

            <div className={styles.whatWeDoColumn}>
              <img src={photoCreate} alt=""></img>
              <h1>Create</h1>
              <p>
                Create your own fully branded event or take advantage of our
                professional design team.
              </p>
            </div>
            <div className={styles.whatWeDoColumn}>
              <img src={photoEngage} alt=""></img>
              <h1>Engage</h1>
              <p>
                Engage attendees with: networking, live & pre-recorded
                broadcasts, chat, polls, Q&A and more!
              </p>
            </div>

            <div className={styles.whatWeDoColumnDivider}></div>

            <div className={styles.whatWeDoColumn}>
              <img src={photoInspire} alt=""></img>
              <h1>Inspire</h1>
              <p>
                Your branded virtual and hybrid experience journey will be one
                of a kind that can inspire your attendees.
              </p>
            </div>

            <div className={styles.whatWeDoColumn}>
              <img src={photoAnalyze} alt=""></img>
              <h1>Analyze</h1>
              <p>
                Prove financial success through live dashboard analytics that
                measures audience engagment, exhibit & sponsorship sales, and
                content impressions.
              </p>
            </div>
          </div>

          <hr className={styles.hr}></hr>

          <div id="why-choose-us" className={styles.anchor}></div>

          <h1 className={styles.title}>Why Choose Us</h1>

          <div className={styles.whyChooseUs}>
            <div className={styles.whyChooseUsColumn}>
              <img src={isMobile ? reachAudiencesMobile : reachAudiences} alt=""></img>
              <h1>Reach Audiences Across the Globe</h1>
              <p>
                Grow your global audience with Nextech AR LiveX. Reach attendees
                like never before with multi-language options, and our intuitive
                mobile companion app. Your guests can explore your experience no
                matter what device they use.
              </p>
            </div>

            <div className={styles.whyChooseUsColumnDivider}></div>

            <div className={styles.whyChooseUsColumn}>
              <img src={isMobile ? buildCommunityMobile : buildCommunity} alt=""></img>
              <h1>
                Build an unforgetable community between your virtual and
                in-person guests
              </h1>
              <p>
                Use our self-serve platform to create stunning floorplans,
                unique exhibitor booths, poster sessions and more. Need some
                help? We have a team of professional event producers, design
                experts and consultants ready to assist you with your experience
                needs.
              </p>
            </div>

            <div className={styles.whyChooseUsColumnDivider}></div>

            <div className={styles.whyChooseUsColumn}>
              <img src={isMobile ? standOutMobile : standOut} alt=""></img>
              <h1>Stand Out From The Crowd With Augmented Reality</h1>
              <p>
                Inspire and immerse your audience with get-out-of-your-seat
                moments created with human holograms, AR portals that transport
                you to different destinations, and AR products beamed into your
                personal space.
              </p>
            </div>
          </div>

          <hr className={styles.hr}></hr>

          <h1 className={styles.title}>Launch any type of experience</h1>

          <div className={styles.experienceBox}>
            {experience.map((object, i) => {
              let extraStyles = "";
              if ((i + 1) % 3 == 0) {
                extraStyles = styles.experienceColumnRight;
              }
              if ((i + 2) % 3 == 0) {
                extraStyles = styles.experienceColumnCenter;
              }
              if ((i + 3) % 3 == 0) {
                extraStyles = styles.experienceColumnLeft;
              }

              if (isMobile) {
                extraStyles = styles.experienceColumnMobile;
              }
              return (
                <div key={i} className={`${styles.experienceColumn} ${extraStyles}`}>
                  <img src={object.img} alt=""></img>
                  <h1>{object.title}</h1>
                </div>
              );
            })}
          </div>

          {isMobile ?
            <h1 className={styles.title2}>
              Need something specific?<br></br>Our team of experts can help.
            </h1>
            :
            <h1 className={styles.title2}>
              Need something specific? Our team of experts can help.
            </h1>
          }
          <Link
            className={styles.link}
            to={`${process.env.PUBLIC_URL + "/contact"}`}
          >
            <button className={styles.defaultButton}>Contact Us</button>
          </Link>

          <hr className={styles.hr}></hr>

          <div id="add-ons" className={styles.anchor}></div>

          {isMobile ? (
            <h1 className={styles.title}>
              Elevate your
              <br />
              experience with
              <br />
              these unique
              <br />
              enhancements
            </h1>
          ) : (
            <h1 className={styles.title}>
              Elevate your experience with these
              <br />
              unique enhancements
            </h1>
          )}

          <div className={styles.addOns}>
            <div className={styles.addOnsColumn}>
              <div className={styles.imageBox}>
                <img src={augmentedRealityIcon} alt=""></img>
              </div>
              <h1>Augmented Reality Upgrades</h1>
              <p>
                Make your event stand out with AR upgrades like: Holograms,
                Portals, AR/3D Products.
              </p>
            </div>
            <div className={styles.addOnsColumn}>
              <div className={styles.imageBox}>
                <img src={mobileAppIcon} alt=""></img>
              </div>
              <h1>Mobile App with White Glove Service</h1>
              <p>
                Recreate and convert your virtual event into a smooth mobile
                experience.
              </p>
            </div>
            <div className={styles.addOnsColumn}>
              <div className={styles.imageBox}>
                <img src={whiteLabelicon} alt=""></img>
              </div>
              <h1>White Label {!isMobile && <br />} Event Type</h1>
              <p>
                Customize your event site URL with your company’s branding and
                colours.
              </p>
            </div>
            <div className={styles.addOnsColumn}>
              <div className={styles.imageBox}>
                <img src={professionalDesignIcon} alt=""></img>
              </div>
              <h1>Professional {!isMobile && <br />} Design Consultation</h1>
              <p>
                Consult with creative designers to enhance your virtual event’s
                branding and theme.
              </p>
            </div>
            <div className={styles.addOnsColumnDivider}></div>
            <div className={styles.addOnsColumn}>
              <div className={styles.imageBox}>
                <img src={eventProducerIcon} alt=""></img>
              </div>
              <h1>Event {!isMobile && <br />}Producer</h1>
              <p>
                Consult a professional broadcast producer to make your virtual
                event run smoother.
              </p>
            </div>
            <div className={styles.addOnsColumn}>
              <div className={styles.imageBox}>
                <img src={apiIntegrationsIcon} alt=""></img>
              </div>
              <h1>API Integration with Registration Systems</h1>
              <p>
                Connect preferred registration systems into the virtual platform
                through an API integrations.
              </p>
            </div>
            <div className={styles.addOnsColumn}>
              <div className={styles.imageBox}>
                <img src={interactiveFloorplansIcon} alt=""></img>
              </div>
              <h1>Interactive Floorplans & Maps</h1>
              <p>
                An interactive floorplan to better showcase your exhibitors live
                locations. A must-have for hybrid events.
              </p>
            </div>
            <div className={styles.addOnsColumn}>
              <div className={styles.imageBox}>
                <img src={expertEventConsultationIcon} alt=""></img>
              </div>
              <h1>Expert Event {!isMobile && <br />} Consultation</h1>
              <p>
                Consult with expert event strategists on how to maximize your
                virtual event experience.
              </p>
            </div>
          </div>

          <hr className={styles.hr}></hr>

          <div id="gallery" className={styles.anchor}></div>

          <h1 className={styles.title}>Experience Gallery</h1>

          <Slider />

          {/* <hr className={styles.hr}></hr>

          <div id="pricing" className={styles.anchor}></div>

          <h1 className={styles.title}>Nextech AR LiveX Pricing Plans</h1>

          <div className={styles.pricingBox}>
            {pricing.map((object, i) => {
              return (
                <div key={i} className={styles.pricingColumn}>
                  {object.price && object.price !== "" ? (
                    <div
                      className={[styles.pricingHeader, object.class].join(" ")}
                    >
                      <h1>{object.title}</h1>
                      <h1>{object.price}</h1>
                      <h3>per month</h3>
                    </div>
                  ) : (
                    <div
                      className={[styles.pricingHeader, object.class].join(" ")}
                    >
                      <h1>{object.title}</h1>
                      <h1 className={styles.customQuote}>Custom Quote</h1>
                    </div>
                  )}
                  <div className={styles.pricingBody}>
                    {object.features.map((feature, i) => {
                      return (
                        <div key={i} className={styles.pricingFeatures}>
                          <img src={check} alt=""></img>
                          <p>{feature}</p>
                        </div>
                      );
                    })}
                  </div>
                  <Link
                    className={styles.link}
                    to={`${process.env.PUBLIC_URL + "/contact"}`}
                  >
                    <button
                      className={[styles.pricingButton, object.class].join(" ")}
                    >
                      Book a Demo
                    </button>
                  </Link>
                </div>
              );
            })}
          </div>

          {!isMobile && (
            <h1 className={styles.title2}>
              Want to learn more about pricing ?
            </h1>
          )}

          <Link
            className={styles.link}
            to={`${process.env.PUBLIC_URL + "/pricing"}`}
          >
            <button className={styles.defaultButton}>
              View Our Pricing Plans
            </button>
          </Link>

          <hr className={styles.hr}></hr>

          <h1 className={styles.title}>How We Compare</h1>

          <div className={styles.compareBox}>
            <div className={styles.column}>
              <span> Price (Yearly)</span>
            </div>

            <div className={styles.column}>
              <span>Live Event Duration Limit</span>
            </div>

            <div className={styles.column}>
              <span>Registered Users Included Per Event</span>
            </div>

            <div className={styles.column}>
              <span>Organizers (admins) included</span>
            </div>

            <div className={styles.column}>
              <span>Ticket Commission Fee</span>
            </div>

            <div className={styles.column}>
              <span>Custom Fields for Registration</span>
            </div>

            <div className={styles.column}>
              <span>Realtime analytics dashboard</span>
            </div>

            <div className={styles.firstBox}>
              <div className={styles.header}>Our Price</div>
              <div>$1,988</div>
              {isMobile ? (
                <div>
                  As Long As
                  <br />
                  Subscription
                  <br />
                  Period
                </div>
              ) : (
                <div>As Long As Subscription Period</div>
              )}

              <div>200</div>
              <div>2</div>
              <div>4% Fee</div>
              <div>Unlimited</div>
              <div>Included</div>
            </div>

            <div className={styles.secondBox}>
              <div className={styles.header}>Competitor Price</div>
              <div>$2,200</div>
              <div>72 hours</div>
              <div>200</div>
              <div>2</div>
              <div>7% Fee</div>
              <div>Not Available</div>
              <div>Not Included</div>
            </div>
          </div> */}

          <hr className={styles.hr}></hr>

          <h1 className={styles.title}>What Our Customers Say About Us...</h1>

          <Testimonials />

          <hr className={styles.hr}></hr>

          <h1 className={styles.title}>Trusted by Companies like...</h1>

          <Partners />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
