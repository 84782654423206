import React from "react";
import { HashLink as Link } from "react-router-hash-link";

import styles from "../assets/css/footer.module.css";

import logo from "../assets/images/logo.png";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className={styles.footerWrapper}>
      <footer>
        <div className={styles.footerHeader}>
          <h1>
            Are You Ready to Try Nextech AR <span>LiveX?</span>
          </h1>
        </div>

        <div className={styles.footerBtn}>
          {/* <button type="submit" value="2" className={styles.fbutton1}>
            Get Started
          </button> */}

          <button type="submit" value="3" className={styles.fbutton2}>
            <Link
              className={styles.link}
              to={`${process.env.PUBLIC_URL + "/contact"}`}
            >
              Book a Demo
            </Link>
          </button>
        </div>

        <div className={styles.footerBody}>
          <div className={styles.footerContent}>
            <h2>Company</h2>
            <Link
              className={styles.link}
              to={`${process.env.PUBLIC_URL + "/#what-we-do"}`}
            >
              <h3>What We Do</h3>
            </Link>
            <Link
              className={styles.link}
              to={`${process.env.PUBLIC_URL + "/#why-choose-us"}`}
            >
              <h3>Why Us</h3>
            </Link>
            <Link
              className={styles.link}
              to={`${process.env.PUBLIC_URL + "/#pricing"}`}
            >
              <h3>Pricing</h3>
            </Link>
            <Link
              className={styles.link}
              to={`${process.env.PUBLIC_URL + "/#gallery"}`}
            >
              <h3>Gallery</h3>
            </Link>
          </div>

          <div className={styles.footerContent}>
            <h2>Resources</h2>
            <h3>Terms</h3>
            <a
              href="https://www.nextechar.com/nextech-ar-solutions-privacy-policy"
              rel="noopener"
              target="_blank"
            >
              <h3>Privacy</h3>
            </a>
            <Link
              className={styles.link}
              to={`${process.env.PUBLIC_URL + "/pricing#faq"}`}
            >
              <h3>FAQ</h3>
            </Link>
          </div>

          <div className={styles.footerContent}>
            <h2>Contact</h2>
            <a href="mailto:info@nextechar.com">
              <h3>info@nextechar.com</h3>
            </a>
            <h3>
              349 Carlaw Ave, Toronto, <br /> ON M4M 2T1
            </h3>
          </div>
        </div>

        <div className={styles.footerBottom}>
          <a href="https://www.nextechar.com/" target="_blank" rel="noopener">
            <img src={logo} alt=""></img>
          </a>
          <h3>Copyright &copy; {year} NextechAR Solution Inc.</h3>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
