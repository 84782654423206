import React, { useState } from "react"; 

import styles from "../assets/css/slider.module.css";

// import image1 from "../assets/images/hover 11.jpg";
// import image2 from "../assets/images/hover 12.jpg";
// import image3 from "../assets/images/hover 13.jpg";
// import image4 from "../assets/images/hover 14.jpg";
// import image5 from "../assets/images/hover 15.jpg";
// import image6 from "../assets/images/hover 16.jpg";
// import image7 from "../assets/images/hover 17.jpg";
// import image8 from "../assets/images/hover 18.jpg";
// import image9 from "../assets/images/hover 19.jpg";
// import image10 from "../assets/images/hover 20.jpg";
// import image11 from "../assets/images/hover 21.jpg";

import image1 from '../assets/images/new_slideshow_1.jpg';
import image2 from '../assets/images/new_slideshow_2.jpg';
import image3 from '../assets/images/new_slideshow_3.jpg';
import image4 from '../assets/images/new_slideshow_4.jpg';
import image5 from '../assets/images/new_slideshow_5.jpg';
import image6 from '../assets/images/new_slideshow_6.jpg';
import image7 from '../assets/images/new_slideshow_7.jpg';
import image8 from '../assets/images/new_slideshow_8.jpg';
import image9 from '../assets/images/new_slideshow_9.jpg';
import image10 from '../assets/images/new_slideshow_10.jpg';
import image11 from '../assets/images/new_slideshow_11.jpg';
import image12 from '../assets/images/new_slideshow_12.jpg';
import image13 from '../assets/images/new_slideshow_13.jpg';
import image14 from '../assets/images/new_slideshow_14.jpg';
import image15 from '../assets/images/new_slideshow_15.jpg';

import left from "../assets/images/left.png";
import right from "../assets/images/right.png";

const Slider = () => {
  const [forceRender, setForceRender] = useState(0);
  const [direction, setDirection] = useState(null);

  const [images, setImages] = useState([
    image1,
    image2,
    // image3,
    image4,
    image5,
    // image6,
    // image7,
    // image8,
    // image9,
    image10,
    image11,
    // image12,
    // image13,
    // image14,
    image15,
    image1,
    image2,
    // image3,
    image4,
    image5,
    // image6,
    // image7,
    // image8,
    // image9,
    image10,
    image11,
    // image12,
    // image13,
    // image14,
    image15,
  ]);

  const prevSlider = () => {
    let newImages = [...images];
    let slideNumber = newImages.pop();
    newImages.unshift(slideNumber);
    setImages(newImages);
    let newValue = forceRender + 1;
    setForceRender(newValue);
    setDirection(styles.fadeInRight);
  };

  const nextSlider = () => {
    // alert("Next clicked");
    let newImages = [...images];
    let slideNumber = newImages.shift();
    newImages.push(slideNumber);
    setImages(newImages);
    let newValue = forceRender + 1;
    setForceRender(newValue);
    setDirection(styles.fadeInLeft);
  };

  return (
    <div key={forceRender} className={styles.sliderContainer}>
      <div className={styles.cardsBox}>
        <div className={[styles.card, styles.hide].join(" ")}></div>
        {images.map((image, i) => {
          return (
            <div key={i} className={styles.card} data-slide={i}>
              <img src={image} className={i === 0 ? direction : null} alt="" />
            </div>
          );
        })}
      </div>

      <div className={styles.customerFeedbackArrowBox}>
        <img onClick={prevSlider} src={left} alt=""></img>
        <div className={styles.customerFeedbackArrowSpace}></div>
        <img onClick={nextSlider} src={right} alt=""></img>
      </div>
    </div>
  );
};

export default Slider;
