import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import styles from "../assets/css/contact.module.css";

import location from "../assets/images/locations.png";
// import phone from "../assets/images/phone.png";
// import mail from "../assets/images/mails.png";
import checkmark from "../assets/images/checkmark 3d 1.png";

import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

import TagManager from "react-gtm-module";
const tagManagerArgs = {
  dataLayer: {
    page: "contact",
  },
  dataLayerName: "PageDataLayer",
};

const Contact = () => {
  /////TAG Manager////////////////////
  TagManager.dataLayer(tagManagerArgs);
  ////////////////////////////////////

  const [submitted, setSubmitted] = useState(false);

  const { loaded, error, formCreated } = useHubspotForm({
    portalId: '4391972',
    formId: '95cdd838-3fd2-4266-a986-5adcb464e91e',
    target: '#hubspotForm',
  });

  useEffect(() => {
    if (document.querySelector('#hubspot-script')) {
      document.querySelector('#hubspot-script').remove();
    }
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    script.id = 'hubspot-script';
    document.head.appendChild(script);

    // script.addEventListener("load", () => {
    // if (window.hbspt) {
    //   window.hbspt.forms.create({
    //     portalId: "4391972",
    //     formId: "d3700434-e791-4950-bb63-4559285d2b17",
    //     region: "na1",
    //     target: "#hubspotForm",
    //     css: "",
    //   });
    // }
    // });

    // window.addEventListener("message", (event) => {
    //   if (
    //     event.data.type === "hsFormCallback" &&
    //     event.data.eventName === "onFormSubmit"
    //   ) {
    //     setSubmitted(true);
    //     window.scrollTo(0, 0);
    //   }
    // });
  }, []);

  return (
    <div>
      <Navbar />
      <div className={styles.bodyWrapper}>
        <div className={styles.main}>
          {submitted ? (
            <div className={styles.contactContainer}>
              <h1>Contact Us</h1>
              <div className={styles.submittedFormbox}>
                <h1>Thank you for contacting us</h1>
                <img src={checkmark} />
                <p>
                  Your message has been sent.
                  <br />
                  One of our representatives will reach
                  <br />
                  out to you as soon as possible.
                </p>
              </div>
            </div>
          ) : (
            <div className={styles.contactContainer}>
              <h1>Contact Us</h1>

              <div className={styles.formContainer}>
                <div id="hubspotForm" className={styles.formContent}></div>
              </div>

              <div className={styles.formContainer}>
                <div className={styles.formContent}>
                  <h1>Our Headquarters</h1>
                  <div className={styles.icon}>
                    <img src={location} alt="" />
                    <h2>
                      349 Carlaw Ave, Toronto,
                      <br />
                      ON M4M 2T1
                    </h2>
                  </div>
                  {/* <div className={styles.icon}>
                  <img src={phone} alt="" />

                  <h2>1-888-526-5542 (ext. 1)</h2>
                </div>
                <div className={styles.icon}>
                  <img src={mail} alt="" />

                  <h2>email</h2>
                </div> */}

                  <div className={styles.map}>
                    <iframe
                      src={
                        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.152939836654!2d-79.34385978486021!3d43.66578895963518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cb7a234c51b5%3A0x6922da6649e3e920!2s349%20Carlaw%20Ave%2C%20Toronto%2C%20ON%20M4M%202T1%2C%20Canada!5e0!3m2!1sen!2smy!4v1615191524568!5m2!1sen!2smy"
                      }
                      title="Our Location"
                      loading="lazy"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
