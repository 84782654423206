import React, { useState, useEffect } from "react";

import styles from "../assets/css/partners.module.css";

//Partners
import amazon from "../assets/images/partners/amazon.png";
import ryerson from "../assets/images/partners/ryerson.png";
import ted from "../assets/images/partners/tedx.png";
import toyota from "../assets/images/partners/toyota.png";
import johnson from "../assets/images/partners/johnson.png";
import viacomcbs from "../assets/images/partners/viacomcbs.png";
import dell from "../assets/images/partners/dell.png";
import unesco from "../assets/images/partners/unesco.png";
import ibm from "../assets/images/partners/IBM.png";
import poly from "../assets/images/partners/poly.png";

import ironman from "../assets/images/partners/ironman.png";
import adi from "../assets/images/partners/adi.png";
import msa from "../assets/images/partners/msa.png";
import bny from "../assets/images/partners/bny.png";
import gsae from "../assets/images/partners/GSAE.png";
import forest from "../assets/images/partners/forest.png";
import ada from "../assets/images/partners/ADA.png";
import tcs from "../assets/images/partners/tcs.png";
import ketocon from "../assets/images/partners/ketocon.png";
import ussd from "../assets/images/partners/USSD.png";

import usf from "../assets/images/partners/USF.png";
import sterilis from "../assets/images/partners/sterilis.png";
import cmu from "../assets/images/partners/CMU.png";
import vertical from "../assets/images/partners/vertical.png";
import spence from "../assets/images/partners/spence.png";
import biosense from "../assets/images/partners/biosense.png";
import mocca from "../assets/images/partners/mocca.png";
import restaurant from "../assets/images/partners/restaurant.png";
import staubli from "../assets/images/partners/staubli.png";
import alberta from "../assets/images/partners/alberta.png";

import left from "../assets/images/left.png";
import right from "../assets/images/right.png";

const Partners = () => {
  const [forceRender, setForceRender] = useState(0);
  const [direction, setDirection] = useState(null);
  // const [width, setWidth] = useState(window.innerWidth);
  // function handleWindowSizeChange() {
  //   setWidth(window.innerWidth);
  // }
  // useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);

  //   return () => {
  //     window.removeEventListener("resize", handleWindowSizeChange);
  //   };
  // }, []);

  // let isMobile = width <= 768 ? true : false;
  // let numberSlides = isMobile ? 0 : 2;
  let numberSlides = 9;

  const [partners, setPartners] = useState([
    amazon,
    ryerson,
    ted,
    toyota,
    johnson,
    viacomcbs,
    dell,
    unesco,
    ibm,
    poly,
    ironman,
    adi,
    msa,
    bny,
    gsae,
    forest,
    ada,
    tcs,
    ketocon,
    ussd,
    usf,
    sterilis,
    cmu,
    vertical,
    spence,
    biosense,
    mocca,
    restaurant,
    staubli,
    alberta,
  ]);

  const prevFeedback = () => {
    let newFeedbacks = [...partners];
    for (let i = 0; i < 10; i++) {
      let slideNumber = newFeedbacks.pop();
      newFeedbacks.unshift(slideNumber);
    }
    setPartners(newFeedbacks);
    let newValue = forceRender + 1;
    setForceRender(newValue);
    setDirection(styles.fadeInLeft);
  };

  const nextFeedback = () => {
    let newFeedbacks = [...partners];
    for (let i = 0; i < 10; i++) {
      let slideNumber = newFeedbacks.shift();
      newFeedbacks.push(slideNumber);
    }
    setPartners(newFeedbacks);
    let newValue = forceRender + 1;
    setForceRender(newValue);
    setDirection(styles.fadeInRight);
  };

  return (
    <div key={forceRender} style={{ overflow: "hidden" }}>
      <div className={[styles.partnersBox, direction].join(" ")}>
        {partners.map((partner, i) => {
          if (i >= 10) return;
          return (
            <div key={i} className={styles.partnersColumn}>
              <img src={partner} alt="" />
            </div>
          );
        })}
      </div>
      <div className={styles.partnersArrowBox}>
        <img onClick={prevFeedback} src={left} alt=""></img>
        <div className={styles.partnersArrowSpace}></div>
        <img onClick={nextFeedback} src={right} alt=""></img>
      </div>
    </div>
  );
};

export default Partners;
