import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
// import { isMobile } from "react-device-detect";

// import logo from "../assets/images/main-logo.png";
import logo from "../assets/images/updated_desktop_logo.png";
import mobileLogo from "../assets/images/mobile-logo.png";
import hamburger from "../assets/images/menu.png";
import close from "../assets/images/close.png";
import styles from "../assets/css/navbar.module.css";
const Navbar = () => {
  const [scrolling, setScrolling] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY < 50) {
        setScrolling(false);
      } else if (window.scrollY > 50) {
        setScrolling(true);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 600 ? true : false;

  return (
    <div
      className={[
        styles.mainNavbarContainer,
        scrolling ? styles.active : "",
      ].join(" ")}
    >
      <div className={styles.navbarContainer}>
        <Link className={styles.link} to={`${process.env.PUBLIC_URL + "/"}`}>
          <img
            src={isMobile ? mobileLogo : logo}
            className={styles.navbarLogo}
            alt=""
          ></img>
        </Link>
        {isMobile && (
          <img
            src={menuOpen ? close : hamburger}
            className={styles.hamburger}
            onClick={() => setMenuOpen(!menuOpen)}
            alt=""
          />
        )}

        <div
          className={[styles.navbarMenu, menuOpen ? styles.active : null].join(
            " "
          )}
        >
          <Link
            className={styles.link}
            smooth={isMobile}
            to={`${process.env.PUBLIC_URL + "/#what-we-do"}`}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            What We Do
          </Link>
          <Link
            className={styles.link}
            smooth={isMobile}
            to={`${process.env.PUBLIC_URL + "/#why-choose-us"}`}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            Why Choose Us
          </Link>
          <Link
            className={styles.link}
            smooth={isMobile}
            to={`${process.env.PUBLIC_URL + "/#add-ons"}`}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            Enhancements
          </Link>
          {/* <Link
            className={styles.link}
            smooth={isMobile}
            to={`${process.env.PUBLIC_URL + "/pricing"}`}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            Pricing
          </Link> */}
          <Link
            className={styles.link}
            smooth={isMobile}
            to={`${process.env.PUBLIC_URL + "/#gallery"}`}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            Gallery
          </Link>

          <div className={styles.navbarButtons}>
            {/* <button className={styles.getStarted}>Get Started</button> */}
            <Link
              className={styles.link}
              smooth={isMobile}
              to={`${process.env.PUBLIC_URL + "/contact"}`}
              style={{ margin: 0 }}
            >
              <button className={styles.login}>Book a Demo</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
