import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./assets/css/App.css";

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Pricing from "./pages/Pricing";
import ScrollToTop from "./helpers/ScrollToTop";

// const Home = lazy(() => import("./pages/Home" /* webpackChunkName: 'Home' */));
// const Contact = lazy(() =>
//   import("./pages/Contact" /* webpackChunkName: 'Contact' */)
// );
// const Pricing = lazy(() =>
//   import("./pages/Pricing" /* webpackChunkName: 'Pricing' */)
// );

const App = () => {
  return (
    <Router>
      <Suspense fallback={null}>
        <ScrollToTop>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/"}`}
              component={Home}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL + "/contact"}`}
              component={Contact}
            />
            {/* <Route
              exact
              path={`${process.env.PUBLIC_URL + "/pricing"}`}
              component={Pricing}
            /> */}
            {/* <Route exact component={Error404} /> */}
          </Switch>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
};

export default App;
