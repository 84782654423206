import React, { useState, useEffect } from "react";

import styles from "../assets/css/testimonials.module.css";

import pittsburgh from "../assets/images/testimonials/pittsburgh.jpg";
import aia from "../assets/images/testimonials/aia.jpg";
import summit from "../assets/images/testimonials/summit.jpg";
import adi from "../assets/images/testimonials/adi.jpg";
import barAssociation from "../assets/images/testimonials/bar-association.jpg";
import hasgtagEvents from "../assets/images/testimonials/hashtag-events.jpg";
import tacvb from "../assets/images/testimonials/tacvb.jpg";
import kinsley from "../assets/images/testimonials/kinsley.jpg";
import kla from "../assets/images/testimonials/kla.jpg";

import left from "../assets/images/left.png";
import right from "../assets/images/right.png";

const Testimonials = () => {
  const [forceRender, setForceRender] = useState(0);
  const [direction, setDirection] = useState(null);
  // const [width, setWidth] = useState(window.innerWidth);
  // function handleWindowSizeChange() {
  //   setWidth(window.innerWidth);
  // }
  // useEffect(() => {
  //   window.addEventListener("resize", handleWindowSizeChange);

  //   return () => {
  //     window.removeEventListener("resize", handleWindowSizeChange);
  //   };
  // }, []);

  // let isMobile = width <= 768 ? true : false;
  // let numberSlides = isMobile ? 0 : 2;
  let numberSlides = 2;

  const [feedbacks, setFeedbacks] = useState([
    {
      img: pittsburgh,
      feedback:
        "I do not know how I would ever run my shows without it!  I actually wouldn't!!!",
      customerName: "-Linda Jo Thornberg",
      customerPosition: "President",
    },
    {
      img: aia,
      feedback:
        "This product has saved me so many hours in the registration of our exhibitors for our state convention.",
      customerName: "-Susie Wiswall",
      customerPosition: "Executive Director",
    },
    {
      img: summit,
      feedback:
        "They're always open to new ideas and suggestions which is awesome. I would highly recommend them to anyone who needs this type of service.",
      customerName: "-Amy Rodriguez",
      customerPosition: "Conference Director",
    },
    {
      img: adi,
      feedback:
        "You guys are one of my favorite partners for your speed, knowledge of the system and responsiveness!",
      customerName: "-Anne-Sophie Whitehead",
      customerPosition: "Conference Director",
    },
    {
      img: barAssociation,
      feedback:
        "I was renewing our contract and thought I would just write and say thanks for making us look so techie!",
      customerName: "-Joanne Hinnendael",
      customerPosition:
        "Director of Professional Development and Member Services",
    },
    {
      img: hasgtagEvents,
      feedback:
        "We didn't know it when we signed up that we couldn't live without it!",
      customerName: "-Andrew Charlton",
      customerPosition: "CEO",
    },
    {
      img: tacvb,
      feedback:
        "It's really everything I hoped for and more. Our app had the features our members need, but also provided some extra bells and whistles that made it even more valuable.",
      customerName: "-Sarah Page",
      customerPosition: "Director",
    },
    {
      img: kinsley,
      feedback:
        "The customer service received after signing up goes unmatched by any other systems I have used.",
      customerName: "-Casey Hiner",
      customerPosition: "Meeting Architect",
    },
    {
      img: kla,
      feedback:
        "It has wrangled our 90 speakers and 60+ sessions into an organized, user-friendly, interactive site that potential attendees can easily peruse.",
      customerName: "-Michelle Jacobi",
      customerPosition: "CAE",
    },
  ]);

  const prevFeedback = () => {
    let newFeedbacks = [...feedbacks];
    for (let i = 0; i < 3; i++) {
      let slideNumber = newFeedbacks.pop();
      newFeedbacks.unshift(slideNumber);
    }
    setFeedbacks(newFeedbacks);
    let newValue = forceRender + 1;
    setForceRender(newValue);
    setDirection(styles.fadeInLeft);
  };

  const nextFeedback = () => {
    let newFeedbacks = [...feedbacks];
    for (let i = 0; i < 3; i++) {
      let slideNumber = newFeedbacks.shift();
      newFeedbacks.push(slideNumber);
    }
    setFeedbacks(newFeedbacks);
    let newValue = forceRender + 1;
    setForceRender(newValue);
    setDirection(styles.fadeInRight);
  };

  return (
    <div key={forceRender} className={styles.customerFeedbackBox}>
      {feedbacks.map((feedback, i) => {
        if (i > numberSlides) return;
        return (
          <div
            key={i}
            className={[styles.customerFeedbackColumn, direction].join(" ")}
          >
            <img src={feedback.img} alt=""></img>
            <hr className={styles.hr} style={{ width: "100%" }} />
            <p>{feedback.feedback}</p>
            <h1>
              {feedback.customerName}, <span>{feedback.customerPosition}</span>
            </h1>
          </div>
        );
      })}

      <div className={styles.customerFeedbackArrowBox}>
        <img onClick={prevFeedback} src={left} alt=""></img>
        <div className={styles.customerFeedbackSpace}></div>
        <img onClick={nextFeedback} src={right} alt=""></img>
      </div>
    </div>
  );
};

export default Testimonials;
